import React from 'react'
import LeafMap from './leaf-map'
import LeafLetMapTesting from './leaf-map-test'

export default function MapSearch({ markers, boundaryData, map_center, bounding_box, handleRadiusPropertiesOnDrag, map_boundary_coordinates, isMarkerHovered, handleRadiusPropertiesOnDraggingNew, geojson, setMapBounds, SetGridProperties, SetCurrentMapBounds, currentMapBounds, is_header_search, mapCurrentZoom, setMapCurrentZoom, filter_with_boundary, updateLeafLetMapBounds, map_new_filter_bounds, mapRef, map_bounds_zoom,address_search_bounds ,clearAddressOnDrag}) {
    return (

        <>

            <div className="colR">
                <div className="outer_map">
                    <div className="map">
                        <div id="map_canvas">

                            <LeafMap markers={markers} boundaryData={boundaryData} map_center={map_center} handleRadiusPropertiesOnDrag={handleRadiusPropertiesOnDrag} isMarkerHovered={isMarkerHovered} handleRadiusPropertiesOnDraggingNew={handleRadiusPropertiesOnDraggingNew} setMapBounds={setMapBounds} SetGridProperties={SetGridProperties} SetCurrentMapBounds={SetCurrentMapBounds} currentMapBounds={currentMapBounds} geojson={geojson} is_header_search={is_header_search} mapCurrentZoom={mapCurrentZoom} setMapCurrentZoom={setMapCurrentZoom} filter_with_boundary={filter_with_boundary} updateLeafLetMapBounds={updateLeafLetMapBounds} map_new_filter_bounds={map_new_filter_bounds} mapRef={mapRef} map_bounds_zoom={map_bounds_zoom} address_search_bounds={address_search_bounds} clearAddressOnDrag={clearAddressOnDrag} />
                            {/* <LeafLetMapTesting markers={markers} boundaryData={boundaryData} map_center={map_center} handleRadiusPropertiesOnDrag={handleRadiusPropertiesOnDrag} isMarkerHovered={isMarkerHovered} handleRadiusPropertiesOnDraggingNew={handleRadiusPropertiesOnDraggingNew} geojson={geojson} /> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
