import React, { useEffect, useState } from "react";
import { changeMonthToDayDate, postExamAnswers } from "../../../helpers/api";
import LoadingScreen from "../../common/LoadingScreen";
import { toast, ToastContainer } from "react-toastify";
import { TOAST_SETTINGS } from "../../../utils/siteSettings";
import Select from "react-select";
import { Controller } from "react-hook-form";
let examId = null;
let person_id = null;
let renterId = null;
let property_id = null;
let screeningRequestId = null;
let questions_arr = [];
let screeningRequestRenterId = null;
let user_verified = false;
let package_upgrade = false;
export default function SendOfferStep5({
  step,
  changeStep,
  watchAllFields,
  handleOfferValidation,
  isFormProcessing,
  exam,
  examQuestions,
  setExamQuestions,
  handleExamChange,
  updateQuestions,
  handleVerify,
  verify,
  handleSetFormWatchData,
  userCheckVerified,
  handleCheckVerified,
  packageUpgrade,
  setPackageUpgrade,
  requestResponse,
  setManualVerify,
  manualVerify,
  checkIdentityVerification,
  setCheckIdentityVerification,
  manualVerifyQuesAnsPost,
  setManualVerifyQuesAnsPost,
}) {
  const [examResult, setExamResult] = useState(false);
  const [examResultLoading, setExamResultLoading] = useState(false);
  examId = exam?.exam?.examId;
  screeningRequestRenterId = exam?.screeningRequestRenterId;
  person_id = exam?.person_id;
  renterId = exam?.renterId;
  property_id = exam?.property_id;
  screeningRequestId = exam?.screeningRequestId;
  questions_arr = exam?.exam?.authenticationQuestions;
  user_verified = exam?.user_verified;
  package_upgrade = exam?.package_upgrade;
  useEffect(() => {
    // if (verify === false && userCheckVerified !== true && userCheckVerified !== 1) {
    if (
      checkIdentityVerification === true &&
      verify === false &&
      manualVerifyQuesAnsPost === true
    ) {
      let watchData={...watchAllFields,dob:changeMonthToDayDate(watchAllFields?.dob)}
      handleOfferValidation(watchData);
    }
  }, []);

  useEffect(() => {
    var examObj = {
      renterId: renterId,
      property_id: property_id,
      screeningRequestId: screeningRequestId,
      examId: examId,
      screeningRequestRenterId: screeningRequestRenterId,
    };
    handleSetFormWatchData(examObj);
    setManualVerify(false);
    user_verified = exam?.user_verified;
    if (user_verified !== undefined) {
      handleCheckVerified(user_verified);
    }
  }, [exam]);
  useEffect(() => {
    if (
      requestResponse !== undefined &&
      requestResponse !== null &&
      requestResponse?.request_status === "ManualVerificationRequired"
    ) {
      setManualVerify(requestResponse);
      var examObj = {
        renterId: requestResponse?.renterId,
        property_id: requestResponse?.property_id,
        screeningRequestId: requestResponse?.screeningRequestId,
        screeningRequestRenterId: requestResponse?.screeningRequestRenterId,
        examId: 0,
      };
      handleSetFormWatchData(examObj);
      setManualVerifyQuesAnsPost(false);
      // changeStep(step + 1);
    }
  }, [requestResponse]);
  useEffect(() => {
    if (user_verified !== undefined) {
      handleCheckVerified(user_verified);
    }
  }, [user_verified]);
  useEffect(() => {
    if (
      package_upgrade !== undefined &&
      package_upgrade !== null &&
      package_upgrade !== false &&
      package_upgrade !== 0
    ) {
      setPackageUpgrade(true);
    }
  }, [package_upgrade]);
  useEffect(() => {
    if (
      questions_arr?.length > 0 &&
      verify === false &&
      userCheckVerified !== true
    ) {
      updateQuestions(questions_arr);
      resetAnswers(questions_arr);
    }
  }, [questions_arr]);
  const resetAnswers = (questions) => {
    var answer_choice = document.getElementsByClassName("answer_choice");
    for (var i = answer_choice.length - 1; i >= 0; i--) {
      answer_choice[i].selectedIndex = 0;
    }
  };
  async function handleExamAnswers(e) {
    e.preventDefault();
    if (
      exam !== null &&
      exam?.exam?.authenticationQuestions !== undefined &&
      exam?.exam?.authenticationQuestions?.length > 0 &&
      userCheckVerified !== true
    ) {
      setExamResultLoading(true);
      let questionFromData = {
        ...examQuestions,
        form_data: watchAllFields,
        person_id: person_id,
      };
      let url =
        "api/exam-answers/" +
        screeningRequestRenterId +
        "/" +
        examId +
        "/" +
        screeningRequestId;
      let answersCheck = await postExamAnswers(questionFromData, url);
      setExamResultLoading(false);
      if (answersCheck.status === 1) {
        setExamResult(true);

        toast.success(answersCheck.msg, TOAST_SETTINGS);

        setTimeout(() => {
          changeStep(step + 1);
          handleVerify(true);
        }, 2000);
      } else {
        if (answersCheck?.questions_status === 1) {
          if (answersCheck?.questions !== "") {
            exam = answersCheck;
            examId = exam?.exam?.examId;
            let questions_arr_exam = exam?.exam?.authenticationQuestions;
            updateQuestions(questions_arr_exam);

            resetAnswers(questions_arr_exam);
          }
        }
        toast.error(answersCheck.msg, TOAST_SETTINGS);
      }
    } else {
      // toast.error("No questions found!",
      //     TOAST_SETTINGS
      // );
    }
  }
  if (isFormProcessing) return <LoadingScreen />;
  return (
    <>
      <fieldset>
        <div className="medium_blk">
          <ToastContainer />

          {verify === false && userCheckVerified !== true ? (
            // userCheckVerified !== true ?
            exam?.user_verified === false &&
            exam !== null &&
            exam?.exam?.authenticationQuestions !== undefined &&
            exam?.exam?.authenticationQuestions?.length > 0 ? (
              <>
                {/* <h4 className="ofer_heading">Questionnaire</h4> */}
                <div className="form_row row">
                  {exam?.exam?.authenticationQuestions.map(
                    (question, index) => {
                      return (
                        <>
                          <div className="col-xs-6">
                            <label>Question:</label>
                            <div className="form_blk question_blk">
                              <select
                                name="question"
                                id=""
                                className="txtBox hidden"
                                onChange={(e) => handleExamChange(index, e)}
                              >
                                <option value={question?.questionKeyName}>
                                  {question?.questionDisplayName}
                                </option>
                              </select>
                              <h6>{question?.questionDisplayName}</h6>
                            </div>
                          </div>
                          <div className="col-xs-6  ">
                            <h6>Answer</h6>
                            <div className="form_blk">
                              <select
                                name="answer"
                                id={"answer_" + index}
                                className="txtBox answer_choice"
                                onChange={(e) => handleExamChange(index, e)}
                              >
                                <option value="0">Select Answer</option>
                                {question?.choices.map((choice, o_index) => {
                                  return (
                                    <option
                                      value={choice?.choiceKeyName}
                                      key={o_index}
                                    >
                                      {choice?.choiceDisplayName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                        </>
                      );
                    }
                  )}
                </div>
              </>
            ) : manualVerify !== false &&
              manualVerify?.request_status === "ManualVerificationRequired" ? (
              <div className="alert alert-danger text-center">
                {manualVerify?.msg}
              </div>
            ) : (
              <div className="alert alert-danger text-center">
                No were questions found!
              </div>
            )
          ) : (
            <div className="alert alert-success text-center">
              Your identity is verified!
            </div>
          )}
          <div className="cmnBtn text-center offering_send_btns">
            <button
              type="button"
              className="webBtn backBtn"
              onClick={() => changeStep(step - 1)}
            >
              Previous
            </button>

            {userCheckVerified === true || userCheckVerified === 1 ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(step + 1)}
              >
                Next
              </button>
            ) : manualVerifyQuesAnsPost === false &&
              manualVerify !== false &&
              manualVerify?.request_status === "ManualVerificationRequired" ? (
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(step + 1)}
              >
                Next
              </button>
            ) : examResult === true || verify === true ? (
              // examResult === true ?
              <button
                type="button"
                className="webBtn colorBtn nextBtn"
                onClick={() => changeStep(step + 1)}
              >
                Next
              </button>
            ) : (
              <button
                className="webBtn colorBtn backBtn"
                type="button"
                onClick={handleExamAnswers}
                disabled={examResultLoading === true ? true : false}
              >
                Next{" "}
                <i
                  className={
                    examResultLoading === true ? "spinner" : "spinner hidden"
                  }
                ></i>
              </button>
            )}
          </div>
        </div>
      </fieldset>
    </>
  );
}
