import http from "../../helpers/http";
import * as helpers from "../../helpers/api";
import { toast } from "react-toastify";
import { TOAST_SETTINGS } from "../../utils/siteSettings";
import Text from "../../components/common/Text";

import {
  FETCH_SEARCH_PROPERTIES_CONTENT,
  FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS,
  FETCH_SEARCH_PROPERTIES_CONTENT_FAILED,
  CHANGE_IS_HEADER_SEARCH_VALUE,
  CHANGE_MAP_BOUNDS,
} from "./actionTypes";

export const fetchSearchProperties = (frmData, currentPage) => (dispatch) => {
  dispatch({
    type: FETCH_SEARCH_PROPERTIES_CONTENT,
    payload: null,
  });
  http
    .post("search-properties/" + currentPage, helpers.doObjToFormData(frmData))
    .then(({ data }) => {
      console.log(data)
      dispatch({
        type: FETCH_SEARCH_PROPERTIES_CONTENT_SUCCESS,
        payload: data,
      });
    })
    .catch((error) => {
      dispatch({
        type: FETCH_SEARCH_PROPERTIES_CONTENT_FAILED,
        payload: error,
      });
    });
};
export const updateISHeaderSearch = (newValue) => (dispatch) => {
  console.log(newValue)
  dispatch({
    type: CHANGE_IS_HEADER_SEARCH_VALUE,
    payload: newValue,
  });
};
export const updateMapBounds = (newValue) => (dispatch) => {
  dispatch({
    type: CHANGE_MAP_BOUNDS,
    payload: newValue,
  });
};
