import React, { useEffect, useState, useRef } from "react";
import ChatSidebar from "./chat/sidebar";
import ChatHeader from "./chat/header";
import ChatMsgBlk from "./chat/msgBlk";
import ChatWriteForm from "./chat/form";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {
  getUserMessages,
  updateMessageSidebarStatus,
  updateMessagesFromReceivedData,
  updateUserArr,
} from "../../../states/actions/sendChat";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../common/LoadingScreen";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  consoleLog,
  format_date,
  format_date_time,
  getServerImage,
  new_moment_date,
} from "../../../helpers/api";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_URL);
const authToken = localStorage.getItem("authToken");

export default function ChatMessages() {
  const messagesContainerRef = useRef(null);
  const [messageLoading, setMessageLoading] = useState(true);
  const [messagesArr, setMessagesArr] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const [attachments, setAttachments] = useState({ loading: false, files: [] });
  const messages = useSelector((state) => state.sendChat.messages);
  const chat_screen_id = useSelector((state) => state.sendChat.chat_screen_id);
  const isChatLoading = useSelector((state) => state.sendChat.isChatLoading);
  const isLoading = useSelector((state) => state.sendChat.isLoading);
  const users = useSelector((state) => state.sendChat.users);
  const member = useSelector((state) => state.sendChat.member);
  const user_info = useSelector((state) => state.sendChat.user_info);
  const receive_message_obj = useSelector(
    (state) => state.sendChat.receive_message_obj
  );
  const new_user_obj = useSelector((state) => state.sendChat.new_user_obj);
  const sidebar_active_status = useSelector(
    (state) => state.sendChat.sidebar_active_status
  );
  const dispatch = useDispatch();
  // const chat_id = searchParams.get('chat_id') !== null ? searchParams.get('chat_id') : '';
  const { chat_id } = useParams();
  useEffect(() => {
    dispatch(getUserMessages({ chat_id: chat_id }));
  }, []);
  useEffect(() => {
    setMessagesArr(messages);
    setTimeout(() => {
      setMessageLoading(false);
      scrollToBottom();
    }, 1000);
  }, [messages]);
  const [usersArr, setUsers] = useState([]);
  useEffect(() => {
    setUsers(users);
  }, [users]);
  useEffect(() => {
    console.log("receive_message_obj",receive_message_obj)
    if (receive_message_obj && receive_message_obj !== null) {
      if (receive_message_obj?.convoId === chat_id) {
        setMessagesArr((prevItems) => [...prevItems, receive_message_obj]);
      }
      dispatch(updateMessagesFromReceivedData(null));
    }
  }, [receive_message_obj]);
  const appendAndSortUsers = (updatedArray, new_user_obj) => {
    const chatIdExists = updatedArray?.some(
      (message) => message?.chat_id === new_user_obj?.chat_id
    );
    let updatedUsers = updatedArray;

    if (!chatIdExists) {
      updatedUsers = [...updatedArray, new_user_obj];
    }
    const updatedData = updatedUsers?.map((item) => {
      if (item.chat_id === new_user_obj?.chat_id) {
        // If the ID matches, update the value
        return {
          ...item,
          unread: new_user_obj?.unread,
          msg: new_user_obj?.msg,
          created_time: new_user_obj?.created_time,
        };
      }
      return item;
    });

    const newUsers = updatedData?.sort((a, b) => {
      const dateA = new Date(a.created_time);
      const dateB = new Date(b.created_time);
      return dateB - dateA;
    });
    setUsers(newUsers);
  };
  useEffect(() => {
    if (new_user_obj && new_user_obj !== null) {
      // const updatedArray = [new_user_obj, ...usersArr];
      appendAndSortUsers(usersArr, new_user_obj);
      setTimeout(() => {
        dispatch(updateMessageSidebarStatus(null));
      }, 500);
    }
  }, [new_user_obj]);
  useEffect(() => {
    if (sidebar_active_status && sidebar_active_status !== null) {
      // const updatedArray = [new_user_obj, ...usersArr];
      appendAndSortUsers(usersArr, sidebar_active_status);
      // dispatch(updateMessageSidebarStatus(null));
    }
  }, [sidebar_active_status]);

  const handleGetUserMessages = (chat_id) => {
    // searchParams.set("chat_id", chat_id)
    //navigate(`${searchParams.toString()}`);
    navigate(`/messages/${chat_id}`);
    dispatch(getUserMessages({ chat_id: chat_id }));
    scrollToBottom();
  };
  const handleSendMessage = (data) => {
    socket.emit("send-message", data);
    consoleLog("Sent object to server:", data);
    const newMsg = {
      msg: data?.message,
      msg_type: "me",
      time_ago: new_moment_date(),
      user_dp: getServerImage("storage/members/", member?.mem_image),
      user_name: member?.mem_fname + " " + member?.mem_lname,
      attachments: attachments?.files,
    };
    const updatesMessages = [...messagesArr, newMsg];
    if (document.getElementById(chat_id)) {
      document.getElementById(chat_id).textContent = data?.message;
    }

    setMessagesArr(updatesMessages);
    scrollToBottom();
    setTimeout(() => {
      setAttachments([]);
      document.getElementById("msg").value = "";
    }, 200);
    const newData = {
      ...data,
      msg: data?.message,
      created_time: new_moment_date(),
      chat_id: chat_id,
      unread: false,
    };
    appendAndSortUsers(usersArr, newData);
    // const updatedArray = usersArr?.map(item => {
    //     if (item.chat_id === chat_id) {
    //         // If the ID matches, update the value
    //         return { ...item, msg: data?.message, created_time: new_moment_date() };
    //     }
    //     return item;
    // });
    // const newUsers = updatedArray?.sort((a, b) => {
    //     const dateA = new Date(a.created_time);
    //     const dateB = new Date(b.created_time);
    //     return dateB - dateA;
    // });
    // setUsers(newUsers);
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = parseFloat(
        messagesContainerRef.current.scrollHeight
      );
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollTop + 300;
    }
  };
  useDocumentTitle("Messages  - Loftus");
  if (isLoading || messageLoading) return <LoadingScreen />;
  return (
    <>
      <main index="" className="main_dashboard" inbox="">
        <div className="contain-fluid">
          <ChatSidebar
            users={usersArr}
            setUsers={setUsers}
            member={member}
            chat_id={chat_id}
            handleGetUserMessages={handleGetUserMessages}
            allUsers={users}
          />
          <div className="chatBlk relative">
            {isChatLoading ? (
              <div className="loadingDisabled">
                <i className="spinner"></i>
              </div>
            ) : (
              ""
            )}
            {chat_screen_id !== null &&
            chat_screen_id !== undefined &&
            chat_screen_id !== "" ? (
              <>
                <ChatHeader user_info={user_info} />
                <div
                  className="chat scrollbar active"
                  data-chat="person1"
                  ref={messagesContainerRef}
                >
                  {messagesArr?.map((message, index) => {
                    return (
                      <ChatMsgBlk type={message?.msg_type} message={message} />
                    );
                  })}
                  {/* <ChatMsgBlk type="you" attachment={true} /> */}
                </div>
                <ChatWriteForm
                  handleSendMessage={handleSendMessage}
                  conversation_id={chat_screen_id}
                  member={member}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  chat_id={chat_id}
                />
              </>
            ) : (
              <div className="no_chat">
                <h4>Select a chat or start a new conversation</h4>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
}
