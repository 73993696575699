import React from "react";
import {
  convertDateOnly,
  format_amount,
  getPackageName,
  getSelectedValue,
  format_date,
} from "../../../../helpers/api";

export default function RentalListingStep4({
  step,
  changeStep,
  watchAllFields,
  openHouses,
}) {
  return (
    <>
      <fieldset>
        <div className="form_row row justify_center_flex">
          <div className="col-xs-9">
            <div className="info_heading_confirm">
              <h4>Rent</h4>
            </div>
            <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
              <li>
                <strong>Available Date</strong>
                <em>{format_date(watchAllFields?.available_date)}</em>
              </li>
              <li>
                <strong>Offer Deadline</strong>
                <em>
                  {watchAllFields?.offer_deadline
                    ? format_date(watchAllFields?.offer_deadline)
                    : "N/A"}
                </em>
              </li>
              <li>
                <strong>Lease Term</strong>
                <em>{watchAllFields?.lease_term}</em>
              </li>
              <li>
                <strong>Rent</strong>
                <em>{format_amount(watchAllFields?.rent_price)}</em>
              </li>
              <li>
                <strong>Deposit</strong>
                <em>{format_amount(watchAllFields?.deposit_type)}</em>
              </li>

              <li>
                <strong>Entire House</strong>
                <em>{watchAllFields?.entire_home ? "Yes" : "No"}</em>
              </li>
            </ul>
            <div className="gap_line_divide"></div>
            <div className="info_heading_confirm">
              <h4>Screening</h4>
            </div>
            <ul className="total_count_price_listing full_wide_price_listing">
              <li>
                <strong>{getPackageName(watchAllFields?.screening)}</strong>
                <em>{watchAllFields?.screening ? "Yes" : "No"}</em>
              </li>
            </ul>
            <div className="gap_line_divide"></div>
            <div className="info_heading_confirm">
              <h4>Identity</h4>
            </div>
            <ul className="total_count_price_listing full_wide_price_listing cell_full_wide_lst_cell">
              <li>
                <strong>Full Name</strong>
                {watchAllFields?.mname !== null &&
                watchAllFields?.mname !== "null" &&
                watchAllFields?.mname !== undefined &&
                watchAllFields?.mname !== "undefined" ? (
                  <em>
                    {watchAllFields?.fname +
                      " " +
                      watchAllFields?.mname +
                      " " +
                      watchAllFields?.lname}
                  </em>
                ) : (
                  <em>{watchAllFields?.fname + " " + watchAllFields?.lname}</em>
                )}
              </li>
              <li>
                <strong>Date of Birth</strong>
                <em>{format_date(watchAllFields?.dob)}</em>
              </li>
              <li>
                <strong>Address</strong>
                <em>
                  {watchAllFields?.address_line2 !== "" &&
                  watchAllFields?.address_line2 !== null &&
                  watchAllFields?.address_line2 !== undefined
                    ? watchAllFields?.address_line1 +
                      ", " +
                      watchAllFields?.address_line2
                    : watchAllFields?.address_line1}
                </em>
              </li>
              <li>
                <strong>City</strong>
                <em>{watchAllFields?.city}</em>
              </li>
              <li>
                <strong>State</strong>
                <em>
                  {watchAllFields?.state_label !== undefined &&
                  watchAllFields?.state_label !== null &&
                  watchAllFields?.state_label !== ""
                    ? watchAllFields?.state_label
                    : ""}
                </em>
              </li>
              <li>
                <strong>ZIP Code</strong>
                <em>{watchAllFields?.zip_code}</em>
              </li>
            </ul>
            {
              openHouses?.openHouses?.length > 0 ?
            <>
            <div className="gap_line_divide"></div>
            <div className="info_heading_confirm">
              <h4>Open Houses</h4>
            </div>
            <ul className="total_count_price_listing full_wide_price_listing">
              {openHouses?.openHouses?.map((openHouse, index) => {
                return (
                  <li key={index}>
                    <strong>{format_date(openHouse?.date)}</strong>
                    <em>
                      {openHouse?.start_time} - {openHouse?.end_time}
                    </em>
                  </li>
                );
              })}
            </ul>
            </>
            :
            ""
}
          </div>
        </div>
        <div className="cmnBtn text-center">
          <button
            type="button"
            className="webBtn backBtn"
            onClick={() => changeStep(step - 1)}
          >
            Previous
          </button>
          <button
            type="button"
            className="webBtn colorBtn nextBtn"
            onClick={() => changeStep(step + 1)}
          >
            Next
          </button>
        </div>
      </fieldset>
    </>
  );
}
